<template>
  <div>
    <TitleMore
      :title="title"
      :isMore="false"
    ></TitleMore>
    <div class="baseCard">
      <el-carousel
        v-if="type.indexOf('ztjy') < 0 && dataListBanner.length>0"
        indicator-position="outside"
        style="height:100%;cursor:pointer"
        class="carousel"
      >
	  <template v-for="(item,index) in dataListBanner">
        <el-carousel-item
          v-if="item.smallImage"
          style="height:100%"
        >
          <div
            class="selfDynamicMain"
            @click="$goto('/detailShow',{id:item.id})"
          >
            <img
              v-if="item.smallImage"
              :src="`${item.smallImage}`"
              alt=""
							style="height: 400px;"
            >
            <h3>{{ item.title }}</h3>
            <TitleLine width="200px"></TitleLine>
            <p>{{item.publishDate}}</p>
          </div>
        </el-carousel-item>
		</template>
      </el-carousel>
      <div
        class="itemText"
		style="margin-bottom: 10px;"
        v-for="(item,index) in dataList"
        :key="index"
      >
        <p @click="$goto('/detailShow',{id:item.id})">
          <img
            src="../../assets/img/list-little.png"
            alt=""
          >
          <span class="itemTextContent">{{item.title}}</span>
        </p>
        <p class="itemTextTime">{{item.publishDate}}</p>
      </div>
      <el-pagination
        :current-page="page"
        :page-size="size"
        layout="total,  prev, pager, next, jumper"
        :total="total"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore";
import TitleLine from "@components/TitleLine";
import apiUrls from "@api";
export default {
  data() {
    return {
      title: [],
      dataListBanner: [],
      dataList: [],
      page: 1,
      size: 15,
      total: 0,
			type:""
    };
  },

  components: {
    TitleMore,
    TitleLine,
  },

  mounted() {
    this.title.push(this.$route.query.name);
    this.getselfDyList(this.$route.query.type);
		this.type = this.$route.query.type;
  },

  methods: {
    // handleSizeChange(val) {
    // },
    handleCurrentChange(val) {
      this.page = val;
      this.getselfDyList(this.$route.query.type);
    },
    //本会动态
    getselfDyList(type) {
      apiUrls
        .selfDynamicList({ node: type, size: this.size, page: this.page - 1 })
        .then((res) => {
			let _this = this;
          if (this.page == 1) {
			  res.results.data.map(v=>{
				  if( _this.dataListBanner.length < 3 && v.smallImage){
					  _this.dataListBanner.push(v)
				  }
			  })
            this.dataList = res.results.data
          } else {
            this.dataListBanner = [];
            this.dataList = res.results.data;
          }
          this.total = res.results.pager.totalElements;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.baseCard {
  /deep/ .el-carousel__container {
    // min-height: 400px !important;
    height: 500px;
  }
  .carousel {
    position: relative;
    padding-bottom: 20px;
    /deep/ .el-carousel__indicators {
      position: absolute;
      bottom: 140px !important;
      left: 44%;
      .el-carousel__indicator {
        width: 10px !important;
        height: 10px !important;
        border-radius: 50%;
        padding: 0px;
        background: #a0a0a0;
        margin: 0px 20px;
        .el-carousel__button {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          opacity: 0;
        }
      }
      .is-active {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #d1292e;
      }
    }
  }
}

.selfDynamicMain {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  h3 {
    font-weight: bold;
    color: #333333;
    font-size: 20px;
    height: 30px;
    text-align: center;
    margin-top: 5px;
  }
  p {
    font-size: 16px;
    text-align: center;
    color: #ababab;
  }
}
.itemTextContent {
	&:hover {
		color: #d1292e;
	}
}
</style>